// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/flags/svg/austria.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/flags/svg/belgium.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/img/flags/svg/denmark.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/img/flags/svg/estonia.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("/static/img/flags/svg/finland.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("/static/img/flags/svg/france.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_7___ = new URL("/static/img/flags/svg/germany.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_8___ = new URL("/static/img/flags/svg/iceland.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_9___ = new URL("/static/img/flags/svg/ireland.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_10___ = new URL("/static/img/flags/svg/italy.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_11___ = new URL("/static/img/flags/svg/latvia.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_12___ = new URL("/static/img/flags/svg/lithuania.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_13___ = new URL("/static/img/flags/svg/luxembourg.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_14___ = new URL("/static/img/flags/svg/netherlands.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_15___ = new URL("/static/img/flags/svg/norway.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_16___ = new URL("/static/img/flags/svg/poland.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_17___ = new URL("/static/img/flags/svg/spain.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_18___ = new URL("/static/img/flags/svg/sweden.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_19___ = new URL("/static/img/flags/svg/uk.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_20___ = new URL("/static/img/flags/svg/us.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_21___ = new URL("/static/img/flags/svg/au.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_22___ = new URL("/static/img/flags/svg/cn.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./choose_phone_country_list.scss"></require>
  <section id="choose_phone_country_list" class="flex-column">
    <div id="header" class="flex-row backdrop-blur">
      <img click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="bank.choose_country1"></h1>
    </div>
    <div class="countries flex-column" class.bind="shaking ? 'shake' : ''">
      <div class="country_card flex-row" click.delegate="next('AT')">
        <img src="${___HTML_LOADER_IMPORT_1___}">
        <p>Austria</p>
        <span>+43</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'AT'"
          class.bind="selectedCountry == 'AT' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('BE')">
        <img src="${___HTML_LOADER_IMPORT_2___}">
        <p>Belgium</p>
        <span>+32</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'BE'"
          class.bind="selectedCountry == 'BE' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('DN')">
        <img src="${___HTML_LOADER_IMPORT_3___}">
        <p>Denmark</p>
        <span>+45</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'DN'"
          class.bind="selectedCountry == 'DN' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('ET')">
        <img src="${___HTML_LOADER_IMPORT_4___}">
        <p>Estonia</p>
        <span>+372</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'ET'"
          class.bind="selectedCountry == 'ET' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('FN')">
        <img src="${___HTML_LOADER_IMPORT_5___}">
        <p>Finland</p>
        <span>+358</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'FN'"
          class.bind="selectedCountry == 'FN' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('FR')">
        <img src="${___HTML_LOADER_IMPORT_6___}">
        <p>France</p>
        <span>+33</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'FR'"
          class.bind="selectedCountry == 'FR' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('DE')">
        <img src="${___HTML_LOADER_IMPORT_7___}">
        <p>Germany</p>
        <span>+49</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'DE'"
          class.bind="selectedCountry == 'DE' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('IS')">
        <img src="${___HTML_LOADER_IMPORT_8___}">
        <p>Iceland</p>
        <span>+354</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'IS'"
          class.bind="selectedCountry == 'IS' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('EI')">
        <img src="${___HTML_LOADER_IMPORT_9___}">
        <p>Ireland</p>
        <span>+353</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'EI'"
          class.bind="selectedCountry == 'EI' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('IT')">
        <img src="${___HTML_LOADER_IMPORT_10___}">
        <p>Italy</p>
        <span>+39</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'IT'"
          class.bind="selectedCountry == 'IT' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('LA')">
        <img src="${___HTML_LOADER_IMPORT_11___}">
        <p>Latvia</p>
        <span>+371</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'LA'"
          class.bind="selectedCountry == 'LA' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('LH')">
        <img src="${___HTML_LOADER_IMPORT_12___}">
        <p>Lithuania</p>
        <span>+370</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'LH'"
          class.bind="selectedCountry == 'LH' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('LU')">
        <img src="${___HTML_LOADER_IMPORT_13___}">
        <p>Luxembourg</p>
        <span>+352</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'LU'"
          class.bind="selectedCountry == 'LU' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('NL')">
        <img src="${___HTML_LOADER_IMPORT_14___}">
        <p>Netherlands</p>
        <span>+31</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'NL'"
          class.bind="selectedCountry == 'NL' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('NO')">
        <img src="${___HTML_LOADER_IMPORT_15___}">
        <p>Norway</p>
        <span>+47</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'NO'"
          class.bind="selectedCountry == 'NO' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('PL')">
        <img src="${___HTML_LOADER_IMPORT_16___}">
        <p>Poland</p>
        <span>+48</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'PL'"
          class.bind="selectedCountry == 'PL' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('ES')">
        <img src="${___HTML_LOADER_IMPORT_17___}">
        <p>Spain</p>
        <span>+34</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'ES'"
          class.bind="selectedCountry == 'ES' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('SW')">
        <img src="${___HTML_LOADER_IMPORT_18___}">
        <p>Sweden</p>
        <span>+46</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'SW'"
          class.bind="selectedCountry == 'SW' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('UK')">
        <img src="${___HTML_LOADER_IMPORT_19___}">
        <p>UK</p>
        <span>+44</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'UK'"
          class.bind="selectedCountry == 'UK' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('US')">
        <img src="${___HTML_LOADER_IMPORT_20___}">
        <p>US</p>
        <span>+1</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'US'"
          class.bind="selectedCountry == 'US' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('AU')">
        <img src="${___HTML_LOADER_IMPORT_21___}">
        <p>AU</p>
        <span>+61</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'AU'"
          class.bind="selectedCountry == 'UK' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
      <div class="country_card flex-row" click.delegate="next('CN')">
        <img src="${___HTML_LOADER_IMPORT_22___}">
        <p>CN</p>
        <span>+1</span>
        <div class="radio-button-true" if.bind="selectedCountry == 'CN'"
          class.bind="selectedCountry == 'CN' ? 'selected' : ''">
          <div class="inside"></div>
        </div>
      </div>
    </div>
    <div class="flex-grow"></div>
    <div class="error" if.bind='isError'>
      <p>
        \${errorMessage}
      </p>
    </div>
  </section>
</template>
`;
// Exports
export default code;