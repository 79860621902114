// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/flags/svg/france.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/flags/svg/uk.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/img/flags/svg/germany.svg", import.meta.url);
// Module
var code = `²<template>
  <require from="./choose_language.scss"></require>
  <require from="../../../components/banka-header/banka-header"></require>
  
  <section id="choose-language" class="flex-column">
    <div id="miniheader" class="flex-row miniheader">
      <img
        id="thumbnail"
        click.delegate="router.navigateBack()"
        src="${___HTML_LOADER_IMPORT_0___}"
      />
    </div>

    <div class="flex-grow"></div>
    <div class="language-list">
      <div class="lang extremity flex-row" click.delegate="selectLang('fr')" class.bind="locale == 'fr' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_1___}">
        <p>Français</p>
      </div>
      <div class="lang extremity flex-row" click.delegate="selectLang('en')" class.bind="locale == 'en' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_2___}">
        <p>English</p>
      </div>
      <div class="lang extremity flex-row" click.delegate="selectLang('de')" class.bind="locale == 'de' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_3___}">
        <p>Deutsch</p>
      </div>
      <!-- Disabled until translation's completed 
        <div class="lang2 extremity flex-row" click.delegate="selectLang('es')"
        class.bind="locale == 'es' ? 'selected' : ''">
        <div class="flex-grow"></div>
        <img src="/static/img/flags/svg/spain.svg">
        <p>Español</p>
      </div> -->
    </div>
    <div class="flex-grow"></div>
    <div class="button_container">
      <button id="profile.choose_language.button.back" class="btn btn-primary" click.delegate="router.navigateBack()">
        <span t="home.finish"></span>
      </button>
    </div>
  </section>
</template>
`;
// Exports
export default code;