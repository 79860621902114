// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/EU/eubank.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/EU/ellipse_77.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/img/currency/bitcoin/bsvfull.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./choose_type_account.scss"></require>
  <section id="choose_type_account" class="flex-column">
    <div id="header" class="flex-row">
      <img click.delegate="goToHome()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="profile.choose.choose_type_account.title">Ajouter un compte</h1>
    </div>
    <div class="spacer-32"></div>
    <div class="listing flex-column" class.bind="shaking ? 'shake' : ''">
      <div class="object flex-row" click.delegate="goToNext('EU')"  class.bind="selectedChoice == 'EU' ? 'selected' : ''">
        <div class="svg-container">
          <img id="thumbnail" src="${___HTML_LOADER_IMPORT_1___}">
          <img id="thumbnail_background" src="${___HTML_LOADER_IMPORT_2___}">
        </div>
        <div class="texte flex-column">
          <p class="title" t="profile.choose.choose_type_account.bank">Bank account</p>
          <p class="desc" t="profile.choose.choose_type_account.bank_desc">New bank account</p>
        </div>
        <div class="flex-grow"></div>
      </div>
      <div class="object flex-row"  click.delegate="goToNext('BSV')"   class.bind="selectedChoice == 'BSV' ? 'selected' : ''">
        <div class="svg-container">
          <img id="thumbnail" src="${___HTML_LOADER_IMPORT_3___}">
          <img id="thumbnail_background" src="${___HTML_LOADER_IMPORT_2___}">
        </div>
        <div class="texte flex-column">
          <p class="title">Bitcoin</p>
          <p class="desc" t="profile.choose.choose_type_account.bitcoin_desc">Bitcoin account</p>
        </div>
        <div class="flex-grow"></div>
      </div>
    </div>
    <div class="flex-grow"></div>
    <div
    class="error"
    class.bind="displayError ? 'shake' : ''"
    if.bind="displayError"
    >
      <span> \${errorMessage} </span>
    </div>
    <div class="flex-grow" if.bind="displayError"></div>
  </section>
</template>
`;
// Exports
export default code;