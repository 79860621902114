import { Router } from "aurelia-router";
import { autoinject } from "aurelia-dependency-injection";
import { CountryModel } from "../../../components/models/YapilyModels/CountryModel";
import { SingletonService } from "singleton";
import { I18N } from "aurelia-i18n";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { UsersHttpClients } from "http_clients/UsersHttpClients";

@autoinject
export class choosePhoneCountryList {
  private locale: string = "fr";
  private country;
  private selectedCountry: string;
  private isError: boolean = false;
  private isCountrySelected: boolean = false;
  private errorMessage: string;
  private shaking: boolean = false;

  constructor(
    private router: Router,
    private singleton: SingletonService,
    private i18n: I18N,
    private userHttpClients: UsersHttpClients
  ) {}

  async next(country) {
    this.selectedCountry = country;
    this.country = this.selectedCountry;
    this.singleton.setPhoneCountry(this.country);
    this.confirm();
    await this.patchProfileWithCountry();
    this.router.navigateBack();
  }

  async patchProfileWithCountry() {
    try {
      let httpRequest = await this.userHttpClients.fetch("/profile", {
        method: "PATCH",
        body: JSON.stringify({
          country: this.country,
        }),
      });
      let is200Ok = await checkResponseStatus(httpRequest);
      let response = await is200Ok.json();
    } catch (error) {
      console.log(error);
    }
  }

  confirm() {
    if (!this.selectedCountry) {
      this.isError = true;
      this.errorMessage = this.i18n.tr("choose_country_list_error");
    } else {
      this.router.navigateBack;
    }
  }

  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

  goBack() {
    this.router.navigateBack();
  }
}
