// Module
var code = `<template>
  <require from="./choose_currency_format.scss"></require>
  <require from="../../../components/banka-header/banka-header"></require>
  <require from="../../../components/ValueConverters/currency-formatter/currency-formatter"></require>

  
  <section id="choose-currency-format" class="flex-column">
    <div id="miniheader" class="flex-row miniheader">
      <div class="flex-grow"></div>
      <h1 i18n="profile.choose_currency_format.title"></h1>
      <div class="flex-grow"></div>
    </div>

    <div class="flex-row input_select">
      <select id="currencySelect" value.bind="currency">
        <option repeat.for="currency of currencies">\${currency}</option>
      </select>
    </div>
    <div class="flex-grow"></div>
    <currency-formatter style="padding: 2rem 0;" 
    currency.two-way="currency" currency-symbol.two-way="currencySymbol" amount.two-way="amountParent" amount-formatted.two-way="amountFormatedParent"
    ></currency-formatter>
    <div id="leftORright" class="flex-row" style="color: aliceblue;">
      <div class="flex-column">
        <div class="flex-row">
          <input type="radio" id="left" name="status" value="left">
          <label for="acronym"><p>\${currency}</p>&nbsp;<p>\${amountFormatedParent}</p></label>
        </div>
        <div class="flex-row">
          <input type="radio" id="right" name="status" value="right">
          <label for="symbol"><p>\${amountFormatedParent}</p>&nbsp;<p>\${currency}</p> </label>
        </div>
      </div>
      <div class="flex-column">
        <div class="flex-row">
          <input type="radio" id="left" name="status" value="left">
          <label for="acronym"><p>\${currencySymbol}</p>&nbsp;<p>\${amountFormatedParent}</p></label>
        </div>
        <div class="flex-row">
          <input type="radio" id="right" name="status" value="right">
          <label for="symbol"><p>\${amountFormatedParent}</p>&nbsp;<p>\${currencySymbol}</p> </label>
        </div>
      </div>
    </div>
    <div class="flex-grow"></div>
    <div class="button_container">
      <button id="profile.choose_language.button.back" class="btn btn-primary" 
              click.delegate="router.navigateBack()">
        <span t="home.back"></span>
      </button>
    </div>
  </section>
</template>
`;
// Exports
export default code;