import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { SingletonService } from "singleton";
import { AuthService } from "aurelia-auth";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-dependency-injection";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { User } from "components/models/UserModel";
import { I18N } from "aurelia-i18n";
import { WalletsManager, WalletInfo } from "services/WalletManager.service";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";
import { connectTo } from "aurelia-store";
import { State } from "state";
import { EventAggregator } from "aurelia-event-aggregator";
import { WalletService } from "services/wallets.service";

@connectTo()
@autoinject
export class ChooseTypeAccount {
  private me: User;
  private my_banks: Array<YapilyBankModel> = [];
  private accounts = [];
  private selectedChoice: string;
  private shaking: boolean = false;
  private fakeDisabled: boolean;
  private allCryptoWallets: Array<WalletInfo>;
  private displayError = false;
  private errorMessage: string;
  private isFirstTime: boolean;
  private walletsOutOfDevice;
  private state: State;

  constructor(
    private router: Router,
    private userHttpClient: UsersHttpClients,
    private singleton: SingletonService,
    private authService: AuthService,
    private walletService: WalletService,
    private i18n: I18N,
    private paymailWalletHttpClient: PaymailWalletClient,
    private ea: EventAggregator
  ) { }


  /**
   * Ending first time flow sending an event that will be picked up
   * by the user.service.ts and will send a websocket request
   * which will return the updated profile which will be updated
   * on all currently connected devices.
   */
  async endFirstTimeFlow() {
    this.ea.publish("profile.update", {
      isFirstTime: false,
    })
  }

  // The state is only available in starting in bind(), hence
  // checking here whether to end first time flow.
  bind() {
    if (this.state.me.isFirstTime) this.endFirstTimeFlow();
  }

  goToAddAccount() {
    this.router.navigateToRoute("add_account");
  }

  gotToAddBitcoinAccount() {
    this.router.navigateToRoute("add_account_bitcoin");
  }

  Choose(selected) {
    this.selectedChoice = selected;
    this.fakeDisabled = false;
  }

  async goToNext(selected) {
    //Subscribed - no questions asked you go through
    if (this.state.me.isSubscribed) {
      (selected === "EU") ? this.router.navigateToRoute("add_account") : this.router.navigateToRoute("add_account_bitcoin");
      return;
    }

    if (selected === "EU") {
      //Unsubscribed - if more than 0 bank (1, 2...) can't go therefore => subscription
      const banks = this.state.wallets.filter( wallet => !wallet.isBlockchain)
      if (banks.length > 0) {
          this.router.navigateToRoute("choose_subscription");
          return
      } 
      // has 0 banks - go through
      this.router.navigateToRoute("add_account");
    }
    
    if (selected === "BSV") {
      //Unsubscribed and more than one private key on this device.
      //This also means each device can have one key
      //This is subject to discussion
      //Maybe we want only 1 wallet, across all devices
      //To entice our subscriptions channel
      //To think
      //in which case:
      //const nbrWalletsWithKeysInIndexDB = this.state.wallets.filter( wallet => wallet.isBlockchain);
      const nbrWalletsWithKeysInIndexDB = this.walletService.count();
      if (nbrWalletsWithKeysInIndexDB > 0) {
        this.errorMessage = this.i18n.tr("home.error.error_bsv_maxed");
        this.displayError = true;
        this.router.navigateToRoute("choose_subscription");
        return
      } 
      
      this.router.navigateToRoute("add_account_bitcoin");
    } 
  }

  async goToHome() {
    this.router.navigateToRoute("home");
  }

  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

}
