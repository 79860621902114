import { I18N } from "aurelia-i18n";
import { autoinject, computedFrom, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { User } from "components/models/UserModel";
import { AuthService } from "aurelia-auth";
import { SingletonService } from "singleton";


@autoinject()
export class chooseLanguage {
  private locale: string;
  private title: string = "";
  private me: User;
  private minDesktopWidth: number = 960

  constructor(private i18n: I18N,
    private router: Router,
    private authService: AuthService,
    private singleton: SingletonService,) {
    this.getLang();
    this.checkWidth()
  }

  checkWidth() {
    if (window.innerWidth < this.minDesktopWidth) {
    }
  }

  async getLang() {
    this.locale = await this.i18n.getLocale();
  }

  async selectLang(lang: string) {
    this.locale = lang.substring(0, 2).toLowerCase();
    this.i18n.setLocale(lang);
    this.singleton.setMyFavoriteLanguage(lang)
    }
}
