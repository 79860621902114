import { computedFrom } from "aurelia-framework";
import { autoinject, bindable } from "aurelia-framework";

@autoinject()
export class chooseCurrencyFormat {
  @bindable currency: string;
  currencies = ["---", "EUR", "BSV", "GBP", "USD"];

  constructor() {}

  @computedFrom("currency")
  get currencySymbol() {
    switch (this.currency) {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "BSV":
        return "₿";
      case "GBP":
        return "£";
      default:
        return "-";
    }
  }
}
